import React, { useEffect, useState } from "react";
import TransactionComponent from "./transactionComponent";
import api from "../../service/api";
import moment from "moment";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import InputField from "../forms/InputField";
import { FaSearch } from "react-icons/fa";
interface TransactionOptions {
  currency: string;
}

interface Transaction {
  id: string;
  meterNumber: string;
  product: string;
  time: string;
  status: string;
  token: string;
  amount: string;
  createdAt: string;
  transactionStatus: string;

  options: TransactionOptions;
}

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getTransactionHistory();
  }, []);

  const getTransactionHistory = async () => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("token");
      const data = await api.req(
        "/transaction/history",
        "GET",
        null,
        authToken
      );

      if (data.status === "success") {
        setTransactions(data.data.details.rows);
      } else {
        setShowAlert(true);
        setAlertMessage(data.message);
        setTimeout(() => setShowAlert(false), 3000);
      }
    } catch (error) {
      console.error(error);
      setShowAlert(true);
      setAlertMessage("Failed to load transaction history.");
    } finally {
      setLoading(false);
    }
  };

  const getActionColor = (status: string): string => {
    switch (status) {
      case "confirmed":
        return "#2CB43C";
      case "pending":
        return "#FFBF00";
      case "failed":
        return "red";
      default:
        return "gray";
    }
  };

  const formatDateTime = (dateString: string): string => {
    return moment(dateString).format("MMMM Do YYYY, h:mm a");
  };

  const copyTokenToClipboard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 2000);
  };
  const [searchQuerry, setSearchQuerry] = React.useState("");
  const filteredTransactions = transactions.filter((transaction) =>
    transaction.meterNumber.includes(searchQuerry)
  );

  return (
    <div
      style={{
        background: "white",
        width: "100%",
        height: "100%",
        padding: "0rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "1rem",
          display: "flex",
          gap: "3rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <AiOutlineArrowLeft
          color={"black"}
          size={20}
          onClick={() => {
            navigate("/homePage");
          }}
          className="bg-[#F4F1F1] "
        />
        <p style={{ fontSize: "1.5rem" }}>Transaction History</p>
      </div>
      <div className="px-4">
        <InputField
          label=""
          value={searchQuerry}
          onChange={(e) => setSearchQuerry(e.target.value)}
          // onKeyDown={(e) => r.handleKeyPress(e)}  // Handle key press
          placeholder="Search Transaction"
          name="searchQuerry"
          type="text" // or "email", depending on your use case
          icon={<FaSearch className="text-[#CC5C08]" />}
        />
      </div>

      <div style={{ width: "100%" }}>
        {loading ? (
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "100vh",
            }}
          >
            <p>Loading...</p>
          </div>
        ) : filteredTransactions.length > 0 ? (
          filteredTransactions.map((transaction) => (
            <TransactionComponent
              getSingleTransaction={() => {
                navigate(`/transaction-details/${transaction.id}`);
                localStorage.setItem("transactionId", transaction.id);
              }}
              key={transaction.id}
              id={transaction.id}
              meterNumber={transaction.meterNumber}
              product={transaction.product}
              time={formatDateTime(transaction.createdAt)}
              color={getActionColor(transaction.status)}
              amount={transaction.amount}
              transactionStatus={transaction.status}
            />
          ))
        ) : (
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "100vh",
            }}
          >
            <p>No transactions found.</p>
          </div>
        )}
      </div>

      {showAlert && (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "100vh",
          }}
        >
          <p style={{ color: "red" }}>{alertMessage}</p>
        </div>
      )}

      {copyAlert && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#CD5C08",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
        >
          Copied!
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import React from "react";
import userIcon from "../../assets/images/userIcon.svg";




const TransactionComponent = ({
  getSingleTransaction,
  amount,
  meterNumber,
  product,
  time,
  color,
  id,
  transactionStatus
}) => {
  console.log(transactionStatus, "transactionStatus");
 
  return (
   <div>
     <div
      className="w-full flex flex-row gap-4 items-center p-4 justify-between cursor-pointer"
      onClick={() => {getSingleTransaction(id); 
        localStorage.setItem("transactionId", id)}}
    >
      <div className="w-10 h-10 bg-gray-100 flex justify-center gap-4 ">
        <img src={userIcon} alt="users-icon" />
         <div className="flex flex-col ">
          <p className="font-semibold text-[14px] leading-[21px]">{meterNumber}</p>
      <p className="text-[12px] leading-[18px]">{time}</p>
      <p className="text-[12px] leading-[18px]">{product}</p>
      </div>
      </div>
      <div className="flex flex-col text-right ">
        <p style={{ color }} className="text-right">₦{amount}</p>
        <p style={{ color }} className="text-right">{transactionStatus === "confirmed" ? "Successfull" : transactionStatus }</p>
      </div>
    </div>
   </div>
  );
};

export default TransactionComponent;
 
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Sterling } from "../../assets/images";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../service/api";
import { Tools } from "../../utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Screen } from "../../components/styles";
import { Spinner } from "../../components/helpers";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaCopy } from "react-icons/fa";
import logoYellow from "../../assets/images/logoYellow.svg";
import { ToastContainer } from "react-toastify";

interface TransactionData {
  meterNumber: string;
  meterType: string;
  token: string;
  product: string;
  amount: number;
  email: string;
  phone: string;
  estate: string;
  reference: string;
  createdAt: string;
}

const Receipt = () => {
  const [loading, setLoading] = useState(true);
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const [transactionData, setTransactionData] =
    useState<TransactionData | null>(null);
  const [estate, setEstate] = useState<string | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const storedEstate = localStorage.getItem("estate");
    setEstate(storedEstate);

    const fetchTransaction = async () => {
      try {
        const authToken = localStorage.getItem("token");
        const data = await api.req(
          `/transaction/${id}`,
          "GET",
          null,
          authToken
        );
        if (data.status === "success") {
          setTransactionData(data.data.details);
        } else {
          console.error("Failed to fetch transaction data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [id]);

  // if (loading) {
  //     return (
  //         <AppContainer>
  //             <Spinner />
  //         </AppContainer>
  //     );
  // }

  // if (!transactionData) {
  //     return (
  //         <AppContainer>
  //             <p>Error loading transaction data. Please try again later.</p>
  //         </AppContainer>
  //     );
  // }

  const copyTokenToClipboard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 2000);
  };

  return (
    <div className="bg-white w-full h-screen p-0 flex flex-col">
      <ToastContainer />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
     <main id="receipt">
     <div className="w-full p-4 flex gap-12 justify-start items-center">
        <AiOutlineArrowLeft
          color="black"
          size={20}
          onClick={() => {
            navigate("/dashboard");
          }}
          className="bg-[#F4F1F1]"
        />
        <p className="text-[1.5rem]">Receipt</p>
      </div>

      {transactionData && (
        <div className="flex flex-col  px-4 text-[12px] ">
          <div className="flex items-center gap-4 flex-col">
            <div className="flex items-center justify-center w-full   gap-1 py-8 h-[20%]">
              <img src={logoYellow} alt="sterling-logo" />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center border-b-[1px] border-b-primary pb-4 border-dashed w-full">
              <h2 className="text-[24px] leading-[24px] font-bold text-primary">
                {Tools.formatCurrency(transactionData.amount)}
              </h2>
              <p className="text-secondaryLight">{transactionData.email}</p>
              <p className="text-secondaryLight">{transactionData.phone}</p>
            </div>
          </div>

          {/* details */}
          <div className="flex justify-between  py-2">
            <div className="flex justify-between items-center w-full">
              <p className="text-xs leading-6 text-secondaryLight">
                Meter Type:
              </p>
              <p className="text-xs  font-semibold ">
                {transactionData?.meterType}
              </p>
            </div>
          </div>
          <div className="flex justify-between w-full  ">
            <div className="flex justify-between w-full items-center">
              <p className="text-xs leading-6 text-secondaryLight">Product:</p>
              <p className="text-xs leading-6 font-semibold ">
                {transactionData?.product}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-xs leading-6 text-secondaryLight">
              Phone Number:
            </p>
            <p className="text-xs leading-6 font-semibold ">
              {transactionData?.phone}
            </p>
          </div>
          <div className="flex justify-between  "></div>
          <div className="flex justify-between items-center">
            <p className="text-xs leading-6 text-secondaryLight">Estate:</p>
            <p className="text-xs leading-6 font-semibold ">
              {transactionData?.email}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-xs leading-6 text-secondaryLight">Date:</p>
            <p className="text-xs leading-6 font-semibold ">
              {moment(transactionData.createdAt).format("YYYY-MM-DD h:mma")}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-xs leading-6 text-secondaryLight">Reference:</p>
            <p className="text-xs leading-6 font-semibold ">
              {transactionData.reference}
            </p>
          </div>
          <div className="pb-6 py-4 border-b-primary  border-dashed w-full border-b-[2px]">
            <div className="px-2  py-2 flex bg-[#FEECBF] justify-between rounded-lg items-center">
              <p className="text-xs leading-6">Token:</p>
              <div className="flex gap-1 items-center">
                <p className="text-sm leading-6 font-semibold text-primary ml-2">
                  {transactionData?.token}
                </p>
                <CopyButton
                  onClick={() => copyTokenToClipboard(transactionData?.token)}
                >
                  <FaCopy size={24} className="text-primary ml-4" />
                </CopyButton>
              </div>
            </div>
          </div>
          <div className="flex flex-col py-6 gap-2 text-[#444444] leading-[18px] text-[12px]">
            <p>For complaints and enquiries, kindly reach us through:</p>
            <p>
              Phone Number:{" "}
              <span className="text-primary font-medium">07024567388</span>
            </p>
            <p>
              Email:{" "}
              <span className="text-primary font-medium">
                contact2sterlingtech.com.ng
              </span>
            </p>
          </div>
          
        </div>
      )}
      {copyAlert && (
        <div className="fixed top-5 left-1/2 transform -translate-x-1/2 bg-[#CD5C08] text-white px-5 py-2 rounded">
          Copied!
        </div>
      )}
     </main>
     <div className="w-full px-4">
            <button
              className="bg-primary w-full text-center text-[16px] hover:bg-[#fff] text-white font-semibold py-2 hover:text-primary px-6 border border-primary  rounded-md"
              onClick={() => Tools.snapshot("receipt", "payment_receipt")}
            >
              Download
            </button>
          </div>
    </div>
  );
};

const CopyButton = styled.button`
  padding: 5px 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0;
`;

export default Receipt;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Alert, Loader } from "../../components/helpers";
import logo1 from "../../assets/images/logo1.svg";
import logo2 from "../../assets/images/logo2.svg";
import frame from "../../assets/images/Frame.png"; // Add this line to import the frame image
import api from "../../service/api";
import InputField from "../../components/forms/InputField";
import "react-toastify/dist/ReactToastify.css";

import { toast, ToastContainer } from "react-toastify";




const Login: React.FC = () => {
  const [userData, setUserData] = useState({
    emailPhone: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordData, setForgotPasswordData] = useState({
    emailPhone: "",
    newPassword: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const { password, emailPhone } = userData;
  const r = {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value.replace(" ", ""),
      });
    },
    revealPassword() {
      setShowPassword(!showPassword);
    },

    async onSubmit(
      e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement>
    ) {
      try {
        e.preventDefault();
        setLoading(true);
        const data = await r.handleLogin();
        if (data?.status === "success") {
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.details));
          // setShowAlert(true);
          // setAlertMessage(data?.data?.message);
          toast.success( "Succesfully Login");
          setColor("#64CCC5");
          setTimeout(() => setShowAlert(false), 3000);
          setTimeout(() => navigate("/homePage"), 4000);
        } else {
          // setShowAlert(true);
          // setAlertMessage(data?.message);
          toast.error(data?.message);
          setTimeout(() => setShowAlert(false), 3000);
        }
        setLoading(false);
      } catch (error) {
        // Handle login error
      }
    },

    handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === "Enter") {
        r.onSubmit(e as any);
      }
    },

    async handleLogin() {
      try {
        const body = {
          emailPhone,
          password,
        };
        const data = await api.req("/user/login", "POST", body);
        return data;
      } catch (error) {
        // Handle error
      }
    },
  };

  
  const handleForgotPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForgotPasswordData({
      ...forgotPasswordData,
      [e.target.name]: e.target.value,
    });
  };


 

  const handleForgotPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await api.req("/user/forgot", "PATCH", forgotPasswordData);
      if (data?.status === "success") {
        setAlertMessage("Password reset successful");
        setColor("#64CCC5");
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
      } else {
        setAlertMessage(data?.message);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
      }
      setShowForgotPasswordModal(false);
    } catch (error) {
      setAlertMessage("Error resetting password");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="bg-orange-500 h-screen w-full relative" >
      <ToastContainer />
      <div className="w-full h-[30%]  absolute  "  >
      <img src={frame} alt="frame" className="w-full h-full object-cover opacity-70 " />
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <div className="flex flex-col justify-center items-center h-full">
        <div className="flex items-center justify-center w-full z-40   gap-1 py-12 h-[20%]">
          <img src={logo1} alt="sterling-logo" />
          <img src={logo2} alt="sterling-logo" />
        </div>
        <div className="flex flex-col w-full h-[80%] rounded-tl-[30px] rounded-tr-[30px] p-6 bg-[#FFFFFF] rounded-[10px] shadow-md pt-24 ">
          <h1 className=" font-bold text-[20px] leading-[20px] font-montserrat  text-[#000] mb-2">
            Welcome Back!!
          </h1>
          <p className="text-[14px] text-[#30324B] font-normal">
            Enter your credentials to access your account.
          </p>
          <form
            onSubmit={(e) => r.onSubmit(e)}
            className="mt-4 flex flex-col w-full"
          >
            <div className="relative">
              <InputField
                label="Email Address"
                value={emailPhone}
                onChange={(e) => r.onChange(e)}
                // onKeyDown={(e) => r.handleKeyPress(e)}  // Handle key press
                placeholder="Enter your email"
                name="emailPhone"
                type="text" // or "email", depending on your use case
              />
            </div>
            <div className=" w-full">
              <InputField
                label="Password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                onChange={(e) => r.onChange(e)}
                value={password}
                // onKeyDown={(e) => r.handleKeyPress(e)}
                obscured={!showPassword}
                suffixIcon={
                  password.length > 0 && (
                    <div
                      className="cursor-pointer"
                      onClick={() => r.revealPassword()}
                    >
                      {showPassword ? (
                        <MdOutlineVisibility
                          size={24}
                          color="#213F7D"
                          title="Hide password"
                        />
                      ) : (
                        <MdOutlineVisibilityOff
                          size={24}
                          color="#213F7D"
                          title="Show password"
                        />
                      )}
                    </div>
                  )
                }
              />
          
            </div>
            <div
                onClick={() => setShowForgotPasswordModal(true)}
                className="text-[#CC5C08] cursor-pointer text-[12px] font-medium text-end w-full py-2"
              >
                Forgot Password?
              </div>
            {loading ? (
              <div className="mt-8 bg-[#CD5C08] text-white p-4 rounded-md flex justify-center">
                <Loader color="#fff" />
              </div>
            ) : (
              <div
                onClick={(e) => r.onSubmit(e)}
                className={`mt-8 p-4 text-white bg-[#CD5C08] rounded-md flex justify-center cursor-pointer ${
                  (!emailPhone || !password) &&
                  "pointer-events-none bg-gray-300 text-[#CD5C08]"
                }`}
              >
                Login
              </div>
            )}
          </form>
          <div className="text-center text-[12px] font-medium leading-[14.4px] py-6">
        Don’t have an account? <span onClick={() => navigate("/register")} className="text-[#CC5C08] cursor-pointer">Register</span>
        </div>
        </div>
      </div>
      {showForgotPasswordModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg w-full max-w-md text-center">
            <h2 className="text-xl font-semibold mb-6">Reset Password</h2>
            <form onSubmit={handleForgotPasswordSubmit}>
              <div className="relative mb-4">
                <input
                  type="text"
                  name="emailPhone"
                  placeholder="Enter your email"
                  value={forgotPasswordData.emailPhone}
                  onChange={handleForgotPasswordChange}
                  required
                  className="w-full p-4 border-2 border-[#CD5C08] rounded-2xl text-black"
                />
              </div>
              <div className="relative mb-6">
                <input
                  type="password"
                  name="newPassword"
                  placeholder="Enter new password"
                  value={forgotPasswordData.newPassword}
                  onChange={handleForgotPasswordChange}
                  required
                  className="w-full p-4 border-2 border-[#CD5C08] rounded-2xl text-black"
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 rounded-lg bg-[#CD5C08] text-white text-sm font-medium mb-4"
              >
                Reset Password
              </button>
            </form>
            <button
              onClick={() => setShowForgotPasswordModal(false)}
              className="text-[#CD5C08] text-sm font-medium"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {/* {showAlert && <Alert message={alertMessage} color={color} />} */}
    </div>
  );
};

export default Login;


import React, { useState, ChangeEvent, FocusEvent } from "react";
import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";

// Define types for the props
interface InputProps {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  type: string;
  optional?: boolean;
  placeholder?: string;
  obscured?: boolean;
  isReadOnly?: boolean;
  suffixIcon?: React.ReactNode;
  meterFound?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  name: string;
  icon?: React.ReactNode;
}

const InputField: React.FC<InputProps> = ({
    isReadOnly,
  label,
  onChange,
  value,
  type,
  optional,
  placeholder,
  obscured = false,
  meterFound,
  suffixIcon,
  onBlur,
  name,
  icon, // New prop for the icon
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="font-inter">
      <label className="text-[#000000] font-inter font-normal text-[12px] my-2 flex leading-[14.4px]">
        {label}
        {optional && (
          <span className="text-xs text-gray-400 pl-1">Optional</span>
        )}
      </label>
      <div className="relative">
        {icon && (
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
            {icon} {/* Dynamically rendering the icon */}
          </span>
        )}
        <input
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          name={name}
          readOnly={isReadOnly ? true : false} // Conditional readOnly property
          type={obscured ? (!showPassword ? "password" : "text") : type}
          className={`appearance-none border ${meterFound ? "border-green-500" : "border-red-500"} border-[#CC5C08] rounded-lg w-full py-3 px-10 text-[#000000] bg-[#FFF9EA] font-normal font-inter leading-tight focus:outline-none`}
          style={icon ? { paddingLeft: "40px" } : { paddingLeft: "10px" }} // Extra padding to make space for the icon
          {...props}
        />
        <div className="right-0 top-0 absolute flex items-center justify-center w-[50px] h-full">
          {obscured ? (
            <span
              className="px-2 text-gray-400"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          ) : suffixIcon ? (
            <span className="px-2 text-gray-400">{suffixIcon}</span>
          ) : type === "email" ? (
            <span className="px-2 text-gray-400">
              <FaEnvelope />
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default InputField;

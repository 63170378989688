import React, { useEffect, useState } from "react";
import api from "../../service/api";
import styled from "styled-components";
import { AiOutlineArrowLeft} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
interface TransactionOptions {
  currency: string;
}

interface Transaction {
  id: string;
  meterNumber: string;
  product: string;
  time: string;
  status: string;
  token: string;
  amount: string;
  createdAt: string;
  transactionStatus: string;

  options: TransactionOptions;
}

const TransactionDetails: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [singleTransaction, setSingleTransaction] =
    useState<Transaction | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const navigate = useNavigate();
  const transactionId = localStorage.getItem("transactionId");

  useEffect(() => {
    getSingleTransactionDetails();
  }, []);

  const getSingleTransactionDetails = async () => {
    const id = transactionId;
    console.log(transactionId, "transctionId")
    try {
      setLoading(true);
      const authToken = localStorage.getItem("token");
      const data = await api.req(`/transaction/${id}`, "GET", null, authToken);

      if (data.status === "success") {
        setSingleTransaction(data.data.details);
      } else {
        setShowAlert(true);
        setAlertMessage(data.message);
        setTimeout(() => setShowAlert(false), 3000);
      }
    } catch (error) {
      console.error(error);
      setShowAlert(true);
      setAlertMessage("Failed to load transaction details.");
    } finally {
      setLoading(false);
    }
  };



  const copyTokenToClipboard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 2000);
  };


  return (
    <div
      style={{
        background: "white",
        width: "100%",
        height: "100vh",
        padding: "0rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "1rem",
          display: "flex",
          gap: "3rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <AiOutlineArrowLeft
          color={"black"}
          size={20}
          onClick={() => {
            navigate("/dashboard");
          }}
          className="bg-[#F4F1F1] "
        />
        <p style={{ fontSize: "1.5rem" }}>Transaction Details</p>
      </div>
      {singleTransaction && (
        <main className="flex flex-col gap-4 px-4 ">
          <div className="px-4 py-4 flex bg-[#FFF9EA] rounded-lg items-center">
            <p className="text-[12px] leading-[24px] ">Token Number:</p>
            <p className="text-[14px] leading-[24px] font-semibold text-primary">
              {singleTransaction?.token}
            </p>
            <CopyButton
              onClick={() => copyTokenToClipboard(singleTransaction.token)}
            >
              <FaCopy size={24} className="text-primary ml-4" />
            </CopyButton>
          </div>
          <main className=" flex flex-col text-[#000000] ">
            <div className="flex justify-between border-b-[1px] border-[#E5E5E5] py-2">
              <div className="flex flex-col ">
                <p className="text-[12px] leading-[24px] ">Meter Number:</p>
                <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight ">
                  {singleTransaction?.meterNumber}
                </p>
              </div>
              <div className="flex flex-col text-right ">
                <p className="text-[12px] leading-[24px] ">Number of units</p>
                <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight ">
                  134 809
                </p>
              </div>
            </div>
            <div className="flex justify-between border-b-[1px] border-[#E5E5E5] py-2">
              <div className="flex flex-col ">
                <p className="text-[12px] leading-[24px] ">Product:</p>
                <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight">
                  {singleTransaction?.product}
                </p>
              </div>
              <div className="flex flex-col text-right ">
                <p className="text-[12px] leading-[24px] ">Payment Type</p>
                <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight">
                  {"CARD"}
                </p>
              </div>
            </div>
            <div className="flex justify-between border-b-[1px] border-[#E5E5E5] py-2">
              <div className="flex flex-col ">
                <p className="text-[12px] leading-[24px] ">Device Estate</p>
                <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight">
                  {/* {singleTransaction?.estate} */}Willcrown
                </p>
              </div>
              <div className="">
              <div className="flex flex-col text-right">
              <p className="text-[12px] leading-[24px] ">Status</p>
                <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight">
                  {singleTransaction?.status}
                </p>
              </div>
              </div>
            </div>

            {/* Payment Break down */}
            <div className="flex flex-col  pt-12 text-secondaryLight">
              <p className="text-[14px] leading-[21px] text-secondary">
                Payment Breakdown
              </p>
              <div className="flex flex-col  ">
                <div className="flex justify-between">
                  <p className="text-[12px] leading-[24px] ">
                    Cost of Utility:
                  </p>
                  <p className="text-[12px] leading-[24px] font-medium text-secondaryLight">
                    ₦ {singleTransaction?.amount}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-[12px] leading-[24px] ">VAT:</p>
                  <p className="text-[12px] leading-[24px] font-medium text-secondaryLight">
                    ₦ 100.00
                  </p>
                </div>
              </div>
              <div className="flex justify-between border-b-[1px] border-[#E5E5E5] py-4">
                <div className="flex justify-between w-full">
                  <p className="text-[12px] leading-[24px] ">Service Charge:</p>
                  <p className="text-[12px] leading-[24px] font-medium text-secondaryLight">
                    {singleTransaction?.amount}
                  </p>
                </div>
              </div>
              <div className="flex justify-between py-4">
                <div className="flex jutiify-between">
                  <p className="text-[12px] leading-[24px] text-[#242222] ">
                    Total:
                  </p>
                </div>
                <p className="text-[12px] leading-[24px] font-bold text-[#242222]">
                  {singleTransaction?.amount}
                </p>
              </div>
            </div>

            <div className="flex justify-between gap-2 pt-6">
            <button
                  className="bg-transparent hover:bg-[#CD5C08] text-primary transition-all font-semibold hover:text-white px-6 py-4 border border-primary hover:border-transparent rounded"
                  onClick={() => navigate(`/receipt/${singleTransaction.id}`)}
                >
                  View Receipt
                </button>
                <button
                  className="bg-primary hover:bg-[#CD5C08] text-white font-semibold py-4 hover:text-white px-6 border border-primary hover:border-transparent rounded"
                  onClick={() => navigate(`/utilities`)}
                >
                  Recharge Again
                </button>
            </div>
          </main>
        </main>
      )}

      {copyAlert && (
        <div
          style={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#CD5C08",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
        >
          Copied!
        </div>
      )}
    </div>
  );
};



const CopyButton = styled.button`
  padding: 5px 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0;


`;


export default TransactionDetails;

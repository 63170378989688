import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo1 from "../../assets/images/logo1.svg";
import logo2 from "../../assets/images/logo2.svg";
import blackIcon from "../../assets/images/blackIcon.svg";
import arrowIcon from "../../assets/images/arrowIcon.svg";
import image1 from "../../assets/images/image1.svg";
import image2 from "../../assets/images/image2.svg";
import image3 from "../../assets/images/image3.svg";
import frame from "../../assets/images/Frame.png";
import notification from "../../assets/images/notification.svg";
import api from "../../service/api";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const HomePage = () => {
  const navigate = useNavigate();
  const users = JSON.parse(localStorage.getItem("user")!);
  const [lastPayment, setLastPayment] = useState({ amount: "0", date: "" });
  interface Transaction {
    meterNumber: string;
    amount: string;
    createdAt: string;
  }
  
  const [transactionHistory, setTransactionHistory] = useState([]);
const history = JSON.parse(localStorage.getItem("transactionHistory")!);
  
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    getLastTransaction();
  }, []);

  const getLastTransaction = async () => {
    try {
      const authToken = localStorage.getItem("token");
      const data = await api.req(
        "/transaction/history",
        "GET",
        null,
        authToken
      );
      if (data.status === "success" && data.data.details.rows.length > 0) {
        const latestTransaction = data.data.details.rows[0];
        setTransactionHistory(data.data.details.rows[0]);
        localStorage.setItem("transactionHistory", JSON.stringify(transactionHistory));
        setLastPayment({
          amount: `₦${parseFloat(latestTransaction.amount).toFixed(2)}`,
          date: moment(latestTransaction.createdAt).format("MMMM Do YYYY"),
        });
      }
    } catch (error) {
      console.error("Failed to load the last transaction:", error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="bg-orange-500 h-full w-full font-poppins bg-custom-pattern bg-repeat bg-blend-overlay relative">
      <div className="w-full h-[30%]  absolute  "  >
      <img src={frame} alt="frame" className="w-full h-full object-cover opacity-70 " />
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landing</title>
      </Helmet>
      <div className="flex flex-col justify-center items-center h-full">
       <div className="py-12 w-full z-40   gap-1 px-4">
       <div className="flex justify-between  items-center">
            <div 
              className="w-[28px] flex bg-slate-300 text-[#000] justify-center items-center h-[28px] rounded-full cursor-pointer"
              onClick={() => setShowLogoutModal(true)}
            >
               {users?.firstName?.charAt(0)}{users?.lastName?.charAt(0)}
            </div>
            <img src={notification} alt="notification" />
        </div>
        <div className="flex items-center justify-center w-full z-40   gap-1  h-full">
          <img src={logo1} alt="sterling-logo" />
          <img src={logo2} alt="sterling-logo" />
        </div>
       </div>
        <div className="bg-[#CC5C08] z-40 px-2 w-[90%] mx-auto rounded-tl-[30px] rounded-tr-[30px]">
          <div className="flex justify-between items-center text-[#fff] p-6">
            <div className="flex flex-col gap-2 text-[#fff]">
              <div className="flex items-center gap-1 text-[14px] ">
                Your <span className="text-[#FDC029] "> Monthly </span> Usage
              </div>
              <p className="text-[32px] leading-[40px] font-semibold">
                120 kWh 
              </p>
            </div>
            <button className="text-[10px] bg-[#fff] text-[#CD5C08] rounded p-2 ">
              Active Meter
            </button>
          </div>
        </div>
        {/* section2 */}
        <div className="flex flex-col w-full h-full rounded-tl-[30px] rounded-tr-[30px]  bg-slate-100  shadow-md pt-12 ">
          <h1 className=" font-bold text-[20px] leading-[20px] px-6 font-montserrat  text-[#000] mb-2">
            Quick Actions
          </h1>
          <div className="flex flex-col gap-6 pt-6 w-full px-6">
            <div
              className="flex justify-between items-center text-[#000] border-[1px] border-[#C4C4C44D] p-4 bg-white rounded-lg relative"
              onClick={() => navigate("/utilities")}
            >
              <div className="flex items-center gap-2 ">
                <img src={blackIcon} alt="icon" /> Buy Utilities
              </div>
              <img src={arrowIcon} alt="arrow_icon" />
              <div
                style={{
                  position: "absolute",
                  right: "-8px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  height: "130%",
                  width: "50px",
                  borderRight: "1px solid #C4C4C44D",
                  borderTop: "1px solid #C4C4C44D",
                  borderBottom: "1px solid #C4C4C44D",
                  borderRadius: "0 10px 10px 0",
                }}
              ></div>
            </div>
            <div
              className="flex justify-between items-center text-[#000] border-[1px] border-[#C4C4C4]/30 rounded-lg p-4 bg-white relative"
              onClick={() => navigate("/dashboard")}
            >
              <div className="flex items-center gap-2 ">
                <img src={blackIcon} alt="icon" /> Transaction History
              </div>
              <img src={arrowIcon} alt="arrow_icon" />
              <div
                style={{
                  position: "absolute",
                  right: "-8px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  height: "130%",
                  width: "50px",
                  borderRight: "1px solid #C4C4C44D",
                  borderTop: "1px solid #C4C4C44D",
                  borderBottom: "1px solid #C4C4C44D",
                  borderRadius: "0 10px 10px 0",
                }}
              ></div>
            </div>
          </div>
          <div className="w-full">
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              // autoPlay
              interval={3000}
              swipeable
              dynamicHeight
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                const defStyle = { marginLeft: 8, cursor: "pointer", fontSize: "20px" };
                const style = isSelected
                  ? { ...defStyle, color: "#CD5C08" }
                  : { ...defStyle, color: "lightgray" };
                return (
                  <span
                    style={style}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  >
                    •
                  </span>
                );
              }}
            >
              <div>
                <img src={image1} alt="img_1" />
              </div>
              <div>
                <img src={image2} alt="img_2" />
              </div>
              <div>
                <img src={image3} alt="img_3" />
              </div>
            </Carousel>
          </div>
          {/* section 3 */}
          <div className="flex flex-col w-full h-full rounded-tl-[30px] rounded-tr-[30px] p-6 bg-[#FFFFFF] rounded-[10px] shadow-md  ">
            <div className="flex flex-col gap-4 pb-6 w-full">
              <div className="flex justify-between items-center text-[#000] py-4">
                <div className="flex items-center text-[16px] font-medium text-[#30324B] ">
                  Last Payment
                </div>
                <h2 className="text-[10px] cursor-pointer text-[#30324B] underline" onClick={() => navigate("/dashboard")}>All Transactions</h2>
              </div>
              <div className="flex justify-between items-center text-[#000] py-4">
                <div className="flex flex-col gap-2 text-[#000]">
                  <div className="flex items-center ">
                    Recharged{" "}
                    <span className="text-[#CD5C08] ">
                      {lastPayment.amount}{" "}
                    </span>
                  </div>
                  <p className="text-[10px] text-[#9DA8B6]">
                    Meter No. {history?.meterNumber}
                  </p>
                </div>
                <button className="text-[10px] text-[#fff] bg-[#CD5C08] rounded p-4 " onClick={() => navigate("/utilities")}>
                  Recharge Again
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* section3 */}
      </div>
      {showLogoutModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>Are you sure you want to logout?</p>
            <div className="flex justify-end gap-4 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded"
                onClick={() => setShowLogoutModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;

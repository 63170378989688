import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Spinner } from "../helpers";
// import { EnergyIcon } from '../../assets/images';
import logo1 from "../../assets/images/logo1.svg";
import logo2 from "../../assets/images/logo2.svg";
import frame from "../../assets/images/Frame.png"; // Add this line to import the frame image
import api from "../../service/api";
import { Helmet } from "react-helmet";

interface Estate {
  name: string;
  [key: string]: any; // Include this if `Estate` might have additional properties
}

const App = () => {
  // const [display, setDisplay] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [estateList, setEstateList] = useState<Estate[]>([]);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    mounted.current = true;
    const getEstates = async () => {
      try {
        setLoading(true);
        const response = await api.req("/estate/all");
        setEstateList(response?.data?.details);
        setLoading(false);
      } catch (error: any) {}
    };
    getEstates();

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (estateList?.length > 0) {
      // Do not set any option as the default selected answer
      setSelectedAnswer(null);
    }
  }, [estateList]);

  if (loading)
    return (
      <AppContainer>
        <Spinner />
      </AppContainer>
    );

  const handleAnswerSelect = (option: string): void => {
    setSelectedAnswer(option);
    window.localStorage.setItem("estate", option);
    navigate("/register");
    console.log(option);
  };
  return (
    <div className="bg-orange-600 h-screen w-full relative">
      <div className="w-full h-[30%]  absolute  "  >
      <img src={frame} alt="frame" className="w-full h-full object-cover opacity-70 " />
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landing</title>
      </Helmet>
      <div className="flex flex-col justify-center items-center h-full">
        <div className="flex items-center justify-center w-full   gap-1 py-12 h-[20%]">
          <img src={logo1} alt="sterling-logo" />
          <img src={logo2} alt="sterling-logo" />
        </div>
        <div className="flex flex-col w-full h-[80%] rounded-tl-[30px] rounded-tr-[30px] p-6 bg-[#FFFFFF] rounded-[10px] shadow-md pt-24 ">
          <h1 className=" font-bold text-[20px] leading-[20px] font-montserrat  text-[#000] mb-2">
            Welcome To Sterling Energy!!
          </h1>
          <p className="text-[14px] text-[#30324B] font-normal">
            Select your estate to start registration
          </p>
          <div className="flex flex-col gap-4 py-6">
            {estateList?.length > 0 ? (
              estateList?.map((item: any, index: React.Key) => (
                <label
                  key={index}
                  className="flex items-center gap-2 cursor-pointer bg-[#FFD8BB] p-4 border-[1px] border-[#CD5C08] rounded-lg  "
                >
                  <input
                    type="radio"
                    name={`estate`}
                    value={item}
                    checked={selectedAnswer === item.name}
                    onChange={() => handleAnswerSelect(item.name)}
                    className="accent-[#CD5C08] w-4 h-4"
                  />
                  <span>{item.name}</span>
                </label>
              ))
            ) : (
              <p>Unable to retrieve list of Estates</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
/** STYLES */
const AppContainer = styled.div`
  background-color: #f8f0e5;
  min-height: 100vh;
  width: 100%;
  display: "flex";
`;

export default App;

import React, { useEffect, useRef, useState } from "react";
import api from "../../service/api";
import styled from "styled-components";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/forms/InputField";
import { toast } from "react-toastify";
import halfProgress from "../../assets/images/halfProgress.svg";
import fullProgress from "../../assets/images/fullProgress.svg";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import classNames from 'classnames';
import { Amount } from "../../components/meter";
import className from "classnames"
import { Tools } from "../../utils";
import { MdVerifiedUser } from "react-icons/md";

interface TransactionOptions {
  currency: string;
}

interface Transaction {
  id: string;
  meterNumber: string;
  product: string;
  time: string;
  status: string;
  token: string;
  amount: string;
  createdAt: string;
  transactionStatus: string;

  options: TransactionOptions;
}

const Utilities: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [powerType, setPowerType] = useState<"Grid" | "Generator" | "">("");
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    product: "",
    email: "",
    phone: "",
    estate: localStorage.getItem("estate") || "",
    meter: "",
    amount: "",
  });

  const formatAmount = (amount: number) => {
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const serviceCharge = 0.015 * Number(userData.amount);
  const totalAmountString = formatAmount(
    Number(userData.amount) + serviceCharge
  );
  const totalAmount = Number(userData.amount) + serviceCharge;

  const subAccount = process.env.REACT_APP_SUB_ACCOUNT;
  const [meterType, setMeterType] = useState("");
  const [meterFound, setMeterFound] = useState(false);
 

  const mounted = useRef(false);
  const estate = localStorage.getItem("estate");
  
  useEffect(() => {
    mounted.current = true;
    const handleMeter = async () => {
      setMeterFound(false);
      try {
        if (userData.meter.length === 11) {
          setLoading(true);
          const authToken = localStorage.getItem("token");
          const headers = {
            Authorization: `Bearer ${authToken}`,
          };
          const data = await api.req(
            `/meter/${userData.meter}/${estate}`,
            "GET",
            null,
            authToken
          );
          if (data.status === "success") {
            const response = await api.req(`/meter/vending/${userData.meter}`);
            if (response.status === "success") {
              setMeterType(data.data.details.meterType);
              setMeterFound(true);
              setLoading(false);
              return;
            } else {
              setShowAlert(true);
              setMeterFound(false);
              setAlertMessage(response.message);
              setTimeout(() => setShowAlert(false), 3000);
              setLoading(false);
              return;
            }
          } else {
            setShowAlert(true);
            setMeterFound(false);
            setAlertMessage(data.message);
            setTimeout(() => setShowAlert(false), 3000);
            setLoading(false);
            return;
          }
        }
        setLoading(false);
      } catch (error) {}
    };
    handleMeter();

    return () => {
      mounted.current = false;
    };
  }, [userData.meter, estate]);
  const r = {
    onChange(
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLSelectElement>
    ) {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value,
      });
    },
  };

  const [isReviewOrder, setIsReviewOrder] = useState(true); // Track the current view

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleReviewOrder = () => {
    if (  
      userData.meter &&
      userData.amount &&
      userData.email &&
      userData.product &&
      userData.phone
    ) {
      setIsReviewOrder(false); // Show the review order view
    } else {
      alert("Please fill in all fields and a verified meter");
    }
  };

  const handlePayNow = () => {
    // Add payment logic here
    alert("Payment Successful!");
  };

  const handleBack = () => {
    setIsReviewOrder(true); // Return to the form view
  };

  // Payment Break down

  const handleFlutterwave = useFlutterwave(
    Tools.paymentConfig(
      totalAmount,
      userData.email,
      userData.phone,
      subAccount,
      userData.product
    ) as any
  );

  const handleTransaction = async (reference: string) => {
    try {
      setLoading(true);
      const body = {
        meterNumber: userData.meter,
        product: userData.product,
        amount: userData.amount,
        email: userData.email,
        phone: userData.phone,
        reference,
        meterType,
        estate,
        powerType: userData.product,
      };
      localStorage.setItem("details", JSON.stringify(body));
      const data = await api.req("/transaction/add", "POST", body);
      if (data.status !== "success") {
        setShowAlert(true);
        setAlertMessage(data.message);
        setTimeout(() => setShowAlert(false), 3000);
        return false;
      }
      localStorage.setItem("date", data.data.details.createdAt);
      const payload = {
        meterNumber: userData.meter,
        amount: userData.amount,
        meterType,
        powerType: userData.product,
      };
      const response = await api.req(
        "/transaction/generate_token",
        "POST",
        payload
      );
      if (response.status !== "success") {
        setShowAlert(true);
        setAlertMessage(response.message);
        setTimeout(() => setShowAlert(false), 3000);
        return false;
      }
      localStorage.setItem("meterToken", response.data.token);
      if (localStorage.getItem("meterToken") !== response.data.token) {
        setShowAlert(true);
        setAlertMessage(
          "Failed to save token locally. Go to your dashboard to get token."
        );
        setTimeout(() => setShowAlert(false), 3000);
        return false;
      }
      const updatedTrx = await api.req(
        `/transaction/${data.data.details.id}`,
        "PATCH",
        { token: response.data.token }
      );
      if (updatedTrx.status === "failed") {
        setShowAlert(true);
        setAlertMessage(updatedTrx.message);
        setTimeout(() => setShowAlert(false), 3000);
        return false;
      }
      navigate("/dashboard");
      return true;
    } catch (error) {
      setShowAlert(true);
      setAlertMessage("An error occurred. Please try again.");
      setTimeout(() => setShowAlert(false), 3000);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = () => {
    if (!userData.product) {
      setShowError(true);
      return;
    }
    try {
      handleFlutterwave({
        callback: async (response) => {
          console.log(response, "response");
          if (
            response.status === "successful" ||
            response.status === "completed"
          ) {
            setUserData({
              meter: "",
              amount: "",
              phone: "",
              email: "",
              product: "",
              estate: "",
            });
            await handleTransaction(response.tx_ref);
          } else {
            setShowAlert(true);
            setAlertMessage("Transaction Failed");
            setTimeout(() => setShowAlert(false), 3000);
            navigate("/homePage");
          }
          closePaymentModal();
        },
        onClose: () => {},
      });
    } catch (error) {
      // Handle error
    }
  };

  return (
    <>
      {isReviewOrder ? (
        <div
          style={{
            background: "white",
            width: "100%",
            height: "100vh",
            padding: "0rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: "2rem 1rem",

              display: "flex",
              gap: "5rem",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <AiOutlineArrowLeft
              color={"black"}
              className="bg-[#F4F1F1]"
              size={20}
              onClick={() => {
                navigate("/homePage");
              }}
            />
            <p style={{ fontSize: "1.5rem" }}>Buy Utilities</p>
          </div>
          <div className="flex justify-between items-center px-4">
            <p className="text-center text-xl">Register to Buy Utilities</p>
            <div className="flex gap-1 items-center">
              <p>step 1 of 2</p>
              <img src={halfProgress} alt="progress" />
            </div>
          </div>
          <form className="mt-4 px-4 flex flex-col w-full">
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700">
                Product Type
              </label>
              <select
                name="product"
                value={userData.product}
                onChange={(e) => r.onChange(e)}
                className="mt-1 block border-[#CC5C08] bg-[#FFF9EA]  w-full pl-3 pr-10 py-4 text-base border-[1px] focus:outline-none focus:ring-[#CC5C08] focus:border-[#CC5C08] sm:text-sm rounded-md"
              >
                <option value="" disabled>
                  Select product type
                </option>
                <option value="Grid">Grid Power</option>
                <option value="Generator">Generator Power</option>
                <option value="Water">Water</option>
              </select>
            </div>
            <div className="relative">
              <InputField
                label="Meter Number"
                value={userData.meter}
                onChange={(e) => r.onChange(e)}
                placeholder="Enter your meter number"
                name="meter"
                type="text" // or "email", depending on your use case
                meterFound={meterFound}
              />
              
              {meterFound ? (
                <p className="text-green-500 text-sm mt-1 flex items-center ">Meter Verified <MdVerifiedUser />  </p>
              ) : (
                !meterFound && userData.meter.length > 11 && (<p className="text-red-500 text-sm mt-1">Meter not found</p>)
              )}
            </div>
            <div className=" w-full">
              <InputField
                label="How much would you like to buy?"
                type={"text"}
                placeholder="Enter amount"
                name="amount"
                onChange={(e) => r.onChange(e)}
                value={userData.amount}
              />
            </div>
            <div className=" w-full">
              <InputField
                label="Email Address"
                type={"text"}
                placeholder="Enter your email address"
                name="email"
                onChange={(e) => r.onChange(e)}
                value={userData.email}
              />
            </div>
            <div className=" w-full">
              <InputField
                label="Phone Number"
                type={"text"}
                placeholder="Enter your phone number"
                name="phone"
                onChange={(e) => r.onChange(e)}
                value={userData.phone}
              />
            </div>

            <div className="w-full py-12">
              <button
                className="bg-primary w-full text-center text-[16px] hover:bg-[#fff] text-white font-semibold py-2 hover:text-primary px-6 border border-primary  rounded-md"
                onClick={handleReviewOrder}
                
              >
                Review Order
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div
          style={{
            background: "white",
            width: "100%",
            height: "100vh",
            padding: "0rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: "2rem 1rem",

              display: "flex",
              gap: "5rem",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <AiOutlineArrowLeft
              color={"black"}
              className="bg-[#F4F1F1]"
              size={20}
              onClick={handleBack}
            />
            <p style={{ fontSize: "1.5rem" }}>Buy Utilities</p>
          </div>
          <div className="flex justify-between pb-6 items-center px-4">
            <p className="text-center text-xl">Review Purchase</p>
            <div className="flex gap-1 items-center">
              <p>step 2 of 2</p>
              <img src={fullProgress} alt="progress" />
            </div>
          </div>
          <main className="flex flex-col gap-4 px-4 ">
            <main className=" flex flex-col text-[#000000] ">
              <div className="flex justify-between  py-2">
                <div className="flex flex-col ">
                  <p className="text-[12px] leading-[18px] font-medium text-[#000000]  ">
                    Product
                  </p>
                  <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight ">
                    {userData.product} {userData.product === "Grid" ? "Power" : ""}
                  </p>
                </div>
                <div className="flex flex-col text-right ">
                  <p className="text-[12px] leading-[24px] ">Meter Number</p>
                  <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight ">
                    {userData.meter}
                  </p>
                </div>
              </div>
              <div className="flex justify-between  py-2">
                <div className="flex flex-col ">
                  <p className="text-[12px] leading-[24px] ">Email Address</p>
                  <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight">
                    {userData?.email}
                  </p>
                </div>
                <div className="flex flex-col text-right ">
                  <p className="text-[12px] leading-[24px] ">Phone Number</p>
                  <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight">
                    {userData?.phone}
                  </p>
                </div>
              </div>
              <div className="flex justify-between  py-2">
                <div className="flex flex-col ">
                  <p className="text-[12px] leading-[24px] ">Device Estate</p>
                  <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight">
                    {userData?.estate}
                  </p>
                </div>
                <div className="">
                  <div className="flex flex-col text-right">
                    <p className="text-[12px] leading-[24px] ">Status</p>
                    <p className="text-[12px] leading-[24px] font-semibold text-secondaryLight">
                      {"Successful"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Payment Break down */}
              <div className="flex flex-col  pt-12 text-secondaryLight">
                <p className="text-[14px] leading-[21px] text-secondary">
                  Payment Breakdown
                </p>
                <div className="flex flex-col  ">
                  <div className="flex justify-between">
                    <p className="text-[12px] leading-[24px] ">
                      Utility Amount:
                    </p>
                    <p className="text-[12px] leading-[24px] font-medium text-secondaryLight">
                      ₦ {formatAmount(Number(userData.amount))}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-[12px] leading-[24px] ">
                      Service Charge:
                    </p>
                    <p className="text-[12px] leading-[24px] font-medium text-secondaryLight">
                      ₦ {formatAmount(serviceCharge)}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between py-4">
                  <div className="flex jutiify-between">
                    <p className="text-[12px] leading-[24px] text-[#242222] ">
                      Total:
                    </p>
                  </div>
                  <p className="text-[12px] leading-[24px] font-bold text-[#242222]">
                    ₦ {totalAmountString}
                  </p>
                </div>
              </div>

              <div className="flex justify-between pt-6 w-full">
                <button
                  className="w-full bg-transparent hover:bg-[#CD5C08] text-primary transition-all font-semibold hover:text-white px-6 py-4 border border-primary hover:border-transparent rounded"
                  onClick={handlePayment}
                >
                  Pay Now
                </button>
              </div>
            </main>
          </main>
        </div>
      )}
    </>
  );
};

export default Utilities;

import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Desktop, Apps } from '../../components/meter';

const Landing = () => {
    const [mobile, setMobile] = useState(false);
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;

        const responsive = () => {
            if (mounted.current) {
                setMobile(window.innerWidth <= 768);
            }
        };

        responsive(); // Run on initial mount

        window.addEventListener('resize', responsive);

        return () => {
            window.removeEventListener('resize', responsive); // Proper cleanup
            mounted.current = false;
        };
    }, []); // Empty dependency array ensures it runs once on mount

    return (
        <AppContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sterling Utilities</title>
            </Helmet> 
            <Wrapper>
                {mobile ? <Apps /> : <Desktop />}
            </Wrapper>
        </AppContainer>
    );
};

/** STYLES */
const AppContainer = styled.div`
min-height: 100vh;
width: 100%;
`;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export default Landing;

import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import logo1 from "../../assets/images/logo1.svg";
import logo2 from "../../assets/images/logo2.svg";
// import { Energy } from '../../assets/images'
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
// import { api } from '../../components/hooks';
import InputField from "../../components/forms/InputField";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { Alert, Loader } from "../../components/helpers";
import api from "../../service/api";
import { Link } from "react-router-dom";

const Register = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    email: "",
    lastName: "",
    phone: "",
    estate: localStorage.getItem('estate') || "", 
    meterNumber: "",
    password: "",
    confirm: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    firstName,
    lastName,
    email,
    phone,
    estate,
    meterNumber,
    password,
    confirm,
  } = userData;

  // const r = {
  //   onChange(e: React.ChangeEvent<HTMLInputElement>) {
  //     setUserData({
  //       ...userData,
  //       [e.target.name]: e.target.value.replace(" ", ""),
  //     });
  //   },
  //   revealPassword() {
  //     setShowPassword(!showPassword);
  //   },
  const r = {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value, 
      });
    },
    revealPassword() {
      setShowPassword(!showPassword);
    },
    

    async onSubmit(
      e:
        | React.KeyboardEvent<HTMLInputElement>
        | React.FormEvent<HTMLFormElement>
        | React.MouseEvent<HTMLDivElement>
    ) {
      try {
        e.preventDefault();
        if (password !== confirm) {
          // setShowAlert(true);
          // setAlertMessage("Passwords do not match.");
         toast.error("Passwords do not match.")
          // setColor("red");
          return; 
        }
        setLoading(true);
        const data = await r.handleLogin();
        if (data?.status === "success") {
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.details));
          // setShowAlert(true);
          setAlertMessage(data?.data?.message);
          toast.success(data?.data?.message || "Sucessfully Register")
          // setColor("#64CCC5");
          // setTimeout(() => setShowAlert(false), 3000);
          setTimeout(() => navigate("/login"), 4000);
        } else {
          // setShowAlert(true);
          // setAlertMessage(data?.message);
          toast.success(data?.message)
          setTimeout(() => setShowAlert(false), 3000);
        }
        setLoading(false);
      } catch (error) {}
    },

    handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === "Enter") {
        r.onSubmit(e);
      }
    },

    async handleLogin() {
      try {
        const body = {
          email,
          firstName,
          lastName,
          meterNumber,
          estate,
          phone,
          password,
          confirm,
        };
        // console.log(body, "body");
        const data = await api.req("/user/register", "POST", body);
       
        // console.log(data);
        return data;
      } catch (error: any) {}
    },
  };
  return (
    <div className="bg-orange-600 h-screen w-full" >
    <Helmet>
      <meta charSet="utf-8" />
      <title>Register</title>
    </Helmet>
    <div className="flex flex-col justify-center items-center h-full">
      <div className="flex items-center justify-center w-full   gap-1 py-12 h-[20%]">
        <img src={logo1} alt="sterling-logo" />
        <img src={logo2} alt="sterling-logo" />
      </div>
      <div className="flex flex-col w-full h-[80%] rounded-tl-[30px] rounded-tr-[30px] p-6 bg-[#FFFFFF] rounded-[10px] shadow-md pt-12 ">
        <h1 className=" font-bold text-[20px] leading-[20px] font-montserrat  text-[#000] mb-2">
          Register
        </h1>
        <p className="text-[14px] text-[#30324B] font-normal">
          Enter your credentials to create an account.
        </p>
        <form
          onSubmit={(e) => r.onSubmit(e)}
          className="mt-4 flex flex-col w-full"
        >
          <div className="relative">
            <InputField
              label="First Name"
              value={firstName}
              onChange={(e) => r.onChange(e)}
              // onKeyDown={(e) => r.handleKeyPress(e)}  // Handle key press
              placeholder="Enter your first name"
              name="firstName"
              type="text" // or "email", depending on your use case
            />
          </div>
          <div className=" w-full">
            <InputField
              label="Last Name"
              type={'text'}
              placeholder="Enter your last name"
              name="lastName"
              onChange={(e) => r.onChange(e)}
              value={lastName}
              // onKeyDown={(e) => r.handleKeyPress(e)}
            />
        
          </div>
          <div className=" w-full">
            <InputField
              label="Email Address"
              type={'text'}
              placeholder="Enter your Email Address"
              name="lastName"
              onChange={(e) => r.onChange(e)}
              value={email}
              // onKeyDown={(e) => r.handleKeyPress(e)}
            />
        
          </div>
          <div className=" w-full">
            <InputField
              label="Phone Number"
              type={'text'}
              placeholder="Enter your phone number"
              name="phone"
              onChange={(e) => r.onChange(e)}
              value={phone}
              // onKeyDown={(e) => r.handleKeyPress(e)}
            />
        
          </div>
          <div className=" w-full">
            <InputField
              label="Meter Number"
              type={'text'}
              placeholder="Enter your meter number"
              name="meterNumber"
              onChange={(e) => r.onChange(e)}
              value={meterNumber}
              // onKeyDown={(e) => r.handleKeyPress(e)}
            />
        
          </div>
          <div className=" w-full">
            <InputField
              label="Selected Estate"
              type={'text'}
              placeholder="Enter your estate"
              name="estate"
              onChange={(e) => r.onChange(e)}
              value={estate}
              isReadOnly={true}
              // onKeyDown={(e) => r.handleKeyPress(e)}
            />
        
          </div>
          <div className=" w-full">
              <InputField
                label="Password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                onChange={(e) => r.onChange(e)}
                value={password}
                // onKeyDown={(e) => r.handleKeyPress(e)}
                obscured={!showPassword}
                suffixIcon={
                  password.length > 0 && (
                    <div
                      className="cursor-pointer"
                      onClick={() => r.revealPassword()}
                    >
                      {showPassword ? (
                        <MdOutlineVisibility
                          size={24}
                          color="#213F7D"
                          title="Hide password"
                        />
                      ) : (
                        <MdOutlineVisibilityOff
                          size={24}
                          color="#213F7D"
                          title="Show password"
                        />
                      )}
                    </div>
                    
                  )
                }
              />
          
            </div>
          <div className=" w-full">
              <InputField
                label="Comfirm Password"
                type={showPassword ? "text" : "password"}
                placeholder="Comfirm your password"
                name="confirm"
                onChange={(e) => r.onChange(e)}
                value={confirm}
                // onKeyDown={(e) => r.handleKeyPress(e)}
                obscured={!showPassword}
                suffixIcon={
                  password.length > 0 && (
                    <div
                      className="cursor-pointer"
                      onClick={() => r.revealPassword()}
                    >
                      {showPassword ? (
                        <MdOutlineVisibility
                          size={24}
                          color="#213F7D"
                          title="Hide password"
                        />
                      ) : (
                        <MdOutlineVisibilityOff
                          size={24}
                          color="#213F7D"
                          title="Show password"
                        />
                      )}
                    </div>
                    
                  )
                }
              />
              {/* { (password !== confirm) && <h1 className="text-red-500">Password is not matching</h1> } */}
          
            </div>
         
            {loading ? (
                <div className="mt-8 bg-[#CD5C08] text-white p-4 rounded-md flex justify-center">
                              <Loader color="#fff" />
              </div>
            ) : (
              <div
                onClick={(e) => r.onSubmit(e)}
                className={`mt-8 p-4 text-white bg-[#CD5C08] rounded-md flex justify-center cursor-pointer
                  ${!email || !password || password !== confirm
                    ? "disabled"
                    : undefined}
                `}
              >
                Register
              </div>
            )}
        </form>
        <div className="text-center text-[12px] font-medium leading-[14.4px] py-6">
        Already have an account? <span onClick={() => navigate("/login")} className="text-[#CC5C08]">Login</span>
      </div>
      </div>
    </div>

    {showAlert && <Alert message={alertMessage} color={color} />}
  </div>
  );
};



export default Register;

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { Helmet } from 'react-helmet'
// import { api } from '../../components/hooks';
import { Alert, Loader } from '../../components/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../service/api';

const UpdateTrx = ({ show }: any) => {
    const [ trxData, setTrxData ] = useState({
        token: "",
        units: ""
    })
    const [loading, setLoading] = useState(false)
    const userToken = localStorage.getItem("token")
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [color, setColor] = useState("")
    const navigate = useNavigate()
    const { token, units } = trxData
    const { transactionId } = useParams()
    const [meter, setMeter] = useState("")
    const mounted = useRef(false)

    useEffect(() => {
        mounted.current = true
        const getTrxDetails = async () => {
          try {
            const data = await api.req(`/transaction/${transactionId}`, undefined, null, token)
            if(data?.status === "success"){
                setMeter(data.data.details.meterNumber)
            } 
          } catch (error: any) {}
        }
        getTrxDetails()

        return () => {
            mounted.current = false
        }

    }, [navigate, userToken, transactionId,token])

    const r = {
        onChange(e: React.ChangeEvent<HTMLInputElement>){
            setTrxData({
                ...trxData,
                [e.target.name]: e.target.value.replace(" ", "")
            })
        },

        async handleUpdate(){
            const body = {
                token,
                units
            }
            try {
                const { data } = await api.req(`/transaction/${transactionId}`, 'PATCH', body, token)
                return data;
            } catch (error: any) {}
        },

        async onSubmit(e: React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement>){
            try {
              e.preventDefault()
              setLoading(true)
              const data = await r.handleUpdate()
              if(data?.data?.badToken) {
                localStorage.removeItem("token")
                localStorage.removeItem("user")
                navigate("/su/admin/login")
              }
              setLoading(false)
              if(data?.status === "success"){
                setShowAlert(true)
                setAlertMessage(data?.data?.message)
                setColor("#64CCC5")
                setTimeout(() => setShowAlert(false), 3000)
                setTimeout(() => navigate("/admin/transactions"), 4000)
                setTrxData({
                    token: "",
                    units: ""
                })
              } else {
                setShowAlert(true)
                setAlertMessage(data?.response?.message ? data?.response?.message : data?.message ? data?.message : data)
                setTimeout(() => setShowAlert(false), 3000)
              }
            } catch (error) {
            }
          },

          handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
            if (e.key === 'Enter') {
              r.onSubmit(e);
            }
          },

    }
  return (
    <AppContainer ml={show ? '20rem' : '10rem'}>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Meter</title>   
      </Helmet>

      <Wrapper>
        <FormContainer 
        onSubmit={(e) => r.onSubmit(e)}
        >

        <h1>Meter Number</h1>
        <input
            readOnly={true}
            value={meter}
        />

        <h1>Token</h1>
        <input
            type="text"
            placeholder="Enter token"
            name='token'
            onChange={(e) => r.onChange(e)} 
            value={token}
            onKeyDown={(e) => r.handleKeyPress(e)}
        />

        <h1>Units</h1>
        <input
            type="text"
            placeholder="Enter units"
            name='units'
            onChange={(e) => r.onChange(e)} 
            value={units}
            onKeyDown={(e) => r.handleKeyPress(e)}
        />

        {
          loading 
          ? <Button><Loader color={ '#fff' } /></Button> 
          : <Button 
          onClick={(e) => r.onSubmit(e)}
          >Update transaction</Button>
        }

        </FormContainer>
      </Wrapper>
      {showAlert && <Alert message={alertMessage} color={color }/>}
    </AppContainer>
  )
}


/** STYLES */
const AppContainer = styled.div<any>`
display: flex;
flex-direction: column;
flex-wrap: wrap;
width: 70%;
margin-left: ${({ ml }) => ml};
padding-top: 30px;
transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const Wrapper = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  margin-left: 10px;
  width: 80%;
`;

const FormContainer = styled.form`
  display: flex;
  width: 60%;
  height: auto;
  flex-direction: column;
  padding: 50px 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  margin-bottom: 50px;
  margin-top: 100px;

  h1 {
    font-size: 13px;
    font-weight: 800;
    /* font-family: 'Roboto Flex', sans-serif; */
    align-self: left;
    color:  #CD5C08;
    margin: 0;
    padding: 0px;
    padding-left: 10px;
    margin-bottom: 1px;
    margin-top: 20px;
  }

  p {
    font-size: 11px;
    font-weight: 400;
    align-self: center;
    color: #989da6;
    margin: 0;
  }

  input {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #dbd9d9;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: transparent;
    color: #0a3d94;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;

  &.disabled {
    pointer-events: none;
    background-color: #ddd;
    color: #CD5C08;
  }
`;

export default UpdateTrx